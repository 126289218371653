<template>
  <div
    class="d-flex justify-content-center"
    style="width: 100%; min-height: 100vh; background-color: #f8f8ff"
  >
    <div
      style="
        position: fixed;
        height: 80px;
        width: 100%;
        background-color: rgb(248, 248, 255);
        z-index: 999;
      "
    >
      <div style="display: flex; justify-content: center">
        <div style="width: 1140px" class="contact-content">
          <div class="p-1">
            <b-link :to="{ path: '/' }" class="brand-logo">
              <img
                style="width: 100px !important; height: 50px !important"
                src="@/assets/images/logo/csm_logo.png"
                alt="logo"
              />
            </b-link>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 1140px; font-size: 16px; font-weight: 400; color: black">
      <div class="h-100 d-flex justify-content-center align-items-center">
        <b-row class="w-100">
          <b-col lg="6 p-4">
            <div class="contact-content">
              <div class="contact-us">
                <div>
                  <span class="h1 text-primary"
                    ><b>Liên hệ với chúng tôi</b></span
                  >

                  <!-- <span class="ml-1 h1 text-warning">Us</span> -->
                </div>
                <div
                  class="mt-2 h3 text-primary"
                  style="color: #d8d6de !important"
                >
                  Thông tin chi tiết
                </div>
                <div class="mt-2 h5 text-primary">
                  <span class="h3 text-primary">CSM </span>
                  <span class="h3 text-warning">Logistics</span>
                </div>

                <div class="mt-2 text-primary">
                  <p class="contact-phone">
                    <span class="h5 text-primary">Phone: </span
                    ><span class="h5 text-warning">+84 906126557</span>
                  </p>
                  <p class="contact-mail">
                    <span class="h5 text-primary">Mail: </span
                    ><span class="h5 text-warning">csmlogdev@gmail.com</span>
                  </p>
                </div>
              </div>
            </div>
          </b-col>

          <b-col class="w-100 p-3" lg="6">
            <validation-observer ref="contactForm" #default="{ valid }">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="handleSubmitForm"
              >
                <!-- Name -->
                <b-form-group label="Name" label-for="login-username">
                  <validation-provider
                    #default="{ errors }"
                    name="fullName"
                    vid="fullName"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="fullName"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      placeholder="Name"
                    />

                    <small class="text-danger">{{
                      handleError(errors[0], "fullName")
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Email -->
                <b-form-group label="Email" label-for="login-username">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    vid="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      placeholder="Email"
                    />

                    <small class="text-danger">{{
                      handleError(errors[0], "email")
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- PhoneNumber -->
                <b-form-group label="Số điện thoại" label-for="phoneNumber">
                  <validation-provider
                    #default="{ errors }"
                    name="phoneNumber"
                    vid="phoneNumber"
                    rules="required"
                  >
                    <b-form-input
                      id="phoneNumber"
                      v-model="phoneNumber"
                      :state="errors.length > 0 ? false : null"
                      name="phoneNumber"
                      placeholder="Số điện thoại"
                    />

                    <small class="text-danger">{{
                      handleError(errors[0], "phoneNumber")
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Message -->
                <b-form-group label="Nội dung" label-for="message">
                  <validation-provider
                    #default="{ errors }"
                    name="message"
                    vid="message"
                    rules="required"
                  >
                    <b-form-textarea
                      id="message"
                      v-model="message"
                      placeholder="Nội dung"
                      rows="5"
                      trim
                    />

                    <small class="text-danger">{{
                      handleError(errors[0], "message")
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <div
                  class="d-flex justify-content-center align-items-center w-100"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="!valid"
                    class="mt-2 w-50"
                  >
                    <template v-if="isLoading">
                      <Spinner variant="light" />
                    </template>

                    <template v-else> Gửi </template>
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { firebaseService } from "@/firebase/firebase";
import { handleError } from "@/helpers/error";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  components: {
    BRow,
    BCol,
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    Spinner,
  },
  data() {
    return {
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
      isLoading: false,
      toast: "",
    };
  },
  methods: {
    handleSubmitForm() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.showToastSuccess(this.toast);
        this.$refs.contactForm.reset();
        this.fullName = "";
        this.email = "";
        this.phoneNumber = "";
        this.message = "";
      }, 1000);
    },
  },
  async mounted() {
    await firebaseService.initFetchAndActivate();
    this.toast = firebaseService.getValue("contact_us").asString();
  },
  computed: {},
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler();
    return { showToastSuccess, showToastError, handleError };
  },
};
</script>

<style lang="scss">
.contact-bg {
  background-image: url("./contact-us.png");
  background-position: center;
  background-size: cover;
  height: 500px;
}

@media screen and (max-width: 480px) {
  .contact-mail {
    display: block;
    margin-left: 0px !important;
    margin-top: 4px;
  }
}

@import "@core/scss/vue/pages/page-misc.scss";
</style>
